import { useState } from 'react';
import './result.css';

export function Result(props) {
  const [clicked, setClicked] = useState(false);

  function clickHandler() {
    window.open(props.content);
    setClicked(true)
  }

  const clickedClass = clicked ? `Result clicked` : `Result`;

  return(
    <span className={ clickedClass } key={ props.key } onClick={ clickHandler }>
      { props.content }
    </span>
  )
}
