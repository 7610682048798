import './form.css';

export function Form(props) {
  return (
    <form className="Form" onSubmit={props.onSubmit} >
      <div className="date-group">
        <div className="date-element">
          <label>Data Inicial</label>
          <input type="date" name="initialDate" />
        </div>

        <div className="date-element">
          <label>Data Final</label>
          <input type="date" name="finalDate" />
        </div>
      </div>

      <label>Estado Origem</label>
      <input type="text" name="state" />

      <label>Marca</label>
      <input type="text" name="seller" />

      <button>Gerar</button>
    </form>
  )
}
