import { Result } from '../result/result';

import './results.css'

export function Results(props) {
  if(!props.results[0])
    return null;

  return (
    <div className="Results">
      { props.results.map(result => {
        return (
          <Result key={ result.createdTimestamp } content={ result.objectUrl } />
        )
      }) }
    </div>
  )
}
