export const generateCtefiles = ({ initialDate, finalDate, state, seller }) => {
  return fetch(process.env.REACT_APP_API_ENDPOINT, {
    method: process.env.REACT_APP_API_METHOD,
    body: JSON.stringify({
      initialDate,
      finalDate,
      state,
      seller
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => response.json())
    .then(({ body }) => body)
}
