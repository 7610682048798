import { useState } from 'react';
import './App.css';
import { Form } from './components/form/form';
import { generateCtefiles } from './helpers/GenerateCtefiles';
import logo from './images/full_abbiamo.png'
import { Results } from './components/results/results';

function App() {
  const [results, setResults] = useState([]);

  function submitHandler(event) {
    event.preventDefault();

    const formData = new FormData(event.target);
    const values = {};

    for(const formElement of formData.entries()) {
      const [key, value] = formElement;

      Object.assign(values, {
        [key]: value
      });
    }

    generateCtefiles(values)
      .then(result => {
        setResults(result)
      });
  }

  return (
    <div className="App">
      <img src={ logo } className="App-logo" alt="Abbiamo Logo" />
      <Form onSubmit={submitHandler} />
      <Results results={results}/>
    </div>
  );
}

export default App;
